import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../util/typography';
import DefaultLayout from '../layouts/DefaultLayout';
import TemplateColumn from '../layouts/TemplateColumn';
import { PageHeader, PageSection } from '../components';
import { indications } from '../util/data/indications';
import { cancerTypes } from '../util/data/cancerTypes';
import { triageCTAs, sharedStrings, createKeynotesText } from '../components/TriageList';
import { setPropsFromURL } from '../util/CTA';
import { createMarkup } from '../util/functions';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
  title: `Sitemap for KEYTRUDA® (pembrolizumab) | HCP`,
  keywords: `keytruda sitemap`,
  description: `Browse the KEYTRUDA® (pembrolizumab) sitemap for faster navigation of the website and cancer treatment pages. View sitemap here.`,
  schemaJsonLD: [
    `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/sitemap/","@type":"MedicalWebPage","name":"Site Map"}`,
    `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/sitemap/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
  ]
}

let cancerTypeOrder = [2,3,5,13,14,9,10,15,16,11,12,17,22,8,4,21,7,19];
let excludeIndications = [11,31,32]; //add differently structured indications (from Triage List) here to avoid build errors

const jobCode = jobCodes[0].jobCode;

const ItemRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(768px - 100%);
    margin: 0 -15px;
`;

const ItemTitleColumn = styled.div`
    min-width: calc(33.3% - 30px);
    flex-grow: 1;
    flex-basis: calc(var(--multiplier) * 999);
    padding: 0 15px;
    margin-bottom: 10px;
`;

const ItemContentColumn = styled.div`
    width: calc(66.7% - 30px);
    flex-grow: 2;
    padding: 0 15px;

    &:first-child {
        padding-top: 0;
        margin-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
`;

const ItemTitle = styled(Heading.H7).attrs({as: 'h2'})``;

const StyledLink = styled.a`
    display: block;
    line-height: 1.38;
    margin-bottom: 14px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const StyledEyebrow = styled(Text.Eyebrow)`
    display: block;
    margin: 30px 0 16px;
`;

const general = {
    title: 'General',
    items: [
        { label: 'Home', url: '/' },
        { label: 'Indications', url: '/approved-indications/' },
        { label: 'Prescribing Information', href: '/prescribing-information/', target: '_blank' },
        { label: 'Contact Us', url: '/contact-us/' },
    ]
};

const biomarkerTesting = {
    title: 'Biomarker Testing',
    items: [
        { label: 'Biomarker Overview', url: '/biomarker-testing/biomarker-overview/' },
        { label: 'PD-L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
        { label: 'MSI/MMR Testing', url: '/biomarker-testing/msi-mmr/' },
        // { label: 'TMB Testing', url: '/biomarker-testing/tmb/' },
    ]
};

const dosingAndPreparation = {
    title: 'Dosing & Preparation',
    items: [
        { label: 'Dosing', url: '/dosing/options/' },
        { label: 'Preparation, Storage & Administration', url: '/dosing/preparation-storage-administration/' },
        { label: 'Dose Modifications', url: '/dosing/dose-modifications/' },
    ]
};

const resources = {
    title: 'Resources',
    items: [
        { label: 'Resource Center', url: '/resources/hcp-and-patient-resources/' },
        { label: 'Patient Support Program', url: '/resources/key-you-patient-support-program/' },
        { label: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        //{ label: 'Surgeon Resources Center', url: '/resources/surgeon-resources/' },
    ]
};

const trimText = (text) => text.replace(/&#8288;/g, '').trim();

const SitemapListContainer = ({ title, children }) => {
    return (
        <ItemRow>
            <ItemTitleColumn>
                <ItemTitle>{title}</ItemTitle>
            </ItemTitleColumn>
            <ItemContentColumn>
                <Text.Body>
                    {children}
                </Text.Body>
            </ItemContentColumn>
        </ItemRow>
    )
}

const SitemapList = ({ listData }) => {
    return (
        <SitemapListContainer title={listData.title}>
            {listData.items.map(item => (
                <StyledLink 
                    dangerouslySetInnerHTML={createMarkup(item.label)}
                    {...setPropsFromURL(item.url)} {...item}
                />
            ))}
        </SitemapListContainer>
    );
};

const EfficacySafetyList = ({ listType }) => {
    return (
        <SitemapListContainer title={sharedStrings[listType].listName}>
            {listType === 'efficacy' && 
                <StyledLink {...setPropsFromURL('/efficacy/')}>Efficacy for Select Indications</StyledLink>
            }
            {listType === 'safety' && 
                <>
                    <StyledLink {...setPropsFromURL('/safety/adverse-reactions/')}>Selected Adverse Reactions for Select Indications</StyledLink>
                    <StyledLink {...setPropsFromURL('/safety/monitoring-managing-adverse-reactions/')}>Treatment Monitoring & Management</StyledLink>
                </>
            }
            {cancerTypeOrder.map(cancerType => (
                <>
                    <StyledEyebrow>{trimText(cancerTypes[cancerType].name)}</StyledEyebrow>
                    {cancerTypes[cancerType].indications.map(indication => {
                        if (excludeIndications.includes(indication)) return;
                        
                        // There are certain cases where the Efficacy KEYNOTE numbers differ from the Safety ones
                        const keynotes = listType === 'efficacy' 
                            ? triageCTAs[indication].efficacyKeynotes || triageCTAs[indication].keynotes // For Efficacy, check first for Efficacy-specific KEYNOTE numbers
                            : triageCTAs[indication].keynotes; 
                        return (
                            <StyledLink
                                dangerouslySetInnerHTML={createMarkup(`${indications[indication].modifier || ''} ${indications[indication].name} ${sharedStrings[listType].ctaDataPrefix} Data From ${createKeynotesText(keynotes)}`)}
                                {...setPropsFromURL(`${sharedStrings[listType].urlPrefix}${triageCTAs[indication].slug}/`)}
                            />
                        )
                    })}
                </>
            ))}
        </SitemapListContainer>
    );
};

const Page = ({ location }) => {
  return (
      <DefaultLayout jobCode={jobCode} location={location} pageMeta={pageMeta}>
          <div data-template-name="template-c">
              {/* Begin Main Column */}
              <TemplateColumn id="template-c">
                    <PageHeader title="Site Map" />
                    <PageSection>
                        <SitemapList listData={general}/>
                    </PageSection>
                    <PageSection bgColor="cloud">
                        <SitemapList listData={biomarkerTesting}/>
                    </PageSection>
                    <PageSection>
                        <SitemapList listData={dosingAndPreparation}/>
                    </PageSection>
                    <PageSection bgColor="cloud">
                        <SitemapList listData={resources}/>
                    </PageSection>
                    <PageSection>
                        <EfficacySafetyList listType="efficacy" />
                    </PageSection>
                    <PageSection bgColor="cloud">
                        <EfficacySafetyList listType="safety" />
                    </PageSection>
              </TemplateColumn>
              {/* End Main Column */}
          </div>
      </DefaultLayout>
  );
};

export default Page;
